import React from "react"
import { jsx, css } from "@emotion/core"

export default function ServicesSection(props) {
  return (
    <section css={services}>
      <div css={servicesContainer}>
        <h2 css={servicesTitle}>Paslaugos</h2>

        <div css={servicesRow}>
          <div css={card}>
            <a css={cardLink} href="/padangu-montavimas">
              <img
                loading="lazy"
                css={cardImage}
                src="https://ik.imagekit.io/jaesjef6q7/general-assets/tr:w-350/GBIT4411_uJKbuR_5AkB5B.jpg"
                alt="padangu montavimas"
              ></img>
              <h4 css={cardTitle}>Padangų montavimas ir balansavimas</h4>
            </a>
          </div>
          <div css={card}>
            <a css={cardLink} href="/tepalu-keitimas">
              <img
                loading="lazy"
                css={cardImage}
                src="https://ik.imagekit.io/jaesjef6q7/general-assets/tr:w-350/GBIT4368_7hjHpvugm.jpg"
                alt="tepalu keitimas"
              ></img>
              <div>
                <h4 css={cardTitle}>Tepalų keitimas</h4>
              </div>
            </a>
          </div>
          <div css={card}>
            <a css={cardLink} href="/vaziuokles-remontas">
              <img
                loading="lazy"
                css={cardImage}
                src="https://ik.imagekit.io/jaesjef6q7/general-assets/tr:w-350/GBIT4339_bLQlE-Dt_pr6.jpg"
                alt="vaziuokles remontas"
              ></img>
              <div>
                <h4 css={cardTitle}>Važiuoklės remontas</h4>
              </div>
            </a>
          </div>
          <div css={card}>
            <a css={cardLink} href="/variklio-diagnostika">
              <img
                loading="lazy"
                css={cardImage}
                src="https://ik.imagekit.io/jaesjef6q7/general-assets/tr:w-350/GBIT4431_OlLWYmhnW.jpg"
                alt="automobilio diagnostika"
              ></img>
              <div>
                <h4 css={cardTitle}>Diagnostika</h4>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
const servicesRow = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const card = css`
  width: 100%;
  max-width: 350px;
  margin: 10px 10px;
  @media only screen and (min-width: 768px) {
    max-width: 260px;
  }
`

const cardLink = css`
  text-decoration: none;
  color: rgb(33, 37, 41);
`
const cardTitle = css`
  margin: 0 auto;
  font-size: 25px;
  padding: 25px;
  font-weight: 700;
  text-align: center;
  background-color: #fff;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
`

const cardImage = css`
  max-width: 100%;
  object-fit: cover;
  display: block;
`

const servicesContainer = css`
  margin: 0 auto;
  max-width: 1120px;
  padding: 50px 20px 70px 20px;
`

const servicesSubtitle = css`
  color: #6c757d;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: none;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`

const services = css``

const servicesTitle = css`
  text-align: center;
  font-size: 37px;
  font-weight: 700;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
`

const benefitCardTitle = css`
  font-size: 24px;
  text-align: center;
  margin: 0 0;
  font-weight: 700;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
`

const benefitCardText = css`
  text-align: center;
  font-size: 16px;
  color: #6c757d;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`

const benefitCard = css`
  width: 300px;
  display: flex;
  flex-direction: column;
  margin: 0px 30px;
  align-items: center;
`

const benefitsArrangement = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
