import React from "react"
import Layout from "../components/layout/Layout"
import styles from "../styles/text-section.module.scss"
import ContactForm from "../components/general/contactForm/kontaktuForma"
import { Helmet } from "react-helmet"
import ServicesSection from "../components/landing/services"

export default function PadanguMontavimas() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Atliekame kokybiškas automobilių remonto paslaugas Kaune.</title>
        <meta
          name="description"
          content="Kokybiškos automobilių remonto paslaugos Kaune: variklio remontas, variklio diagnostika, važiuoklės remontas, padangų keitimas, padangų remontas, ratų montavimas, ratų balansavimas, tepalų keitimas."
        ></meta>
      </Helmet>
      <section className={`${styles.textSection} container`}>
        <h1>Autoserviso paslaugos</h1>
        <p>
          Nedvejokite – rinkitės mūsų autoservisą. Jūsų automobiliu pasirūpins
          patyrę meistrai, skirsime visą dėmesį ir turimą įrangą, kad
          išsiaiškintume automobilio problemas ir pateiktume jų sprendimo būdus.
        </p>
        <p>
          Tik tinkamai sutvarkę automobilį grąžinsime jį jums, kad toliau
          galėtumėte saugiai važinėti. Dirbame greitai ir efektyviai, o remonto
          skaidrumu rūpinamės nuo pirmojo susitikimo. Įvertiname gedimus,
          pateikiame preliminarią darbų sąmatą ir tik tada imamės remonto darbų.
        </p>
      </section>
      <ServicesSection />
      <ContactForm />
    </Layout>
  )
}
