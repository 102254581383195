import React from "react"
import { jsx, css } from "@emotion/core"

export default function ContactForm(props) {
  return (
    <section css={reservationSection}>
      <div css={reservationContainer}>
        <div>
          <h2 css={reservationTitle}>Turite klausimų ar norite rezervuotis?</h2>
          <h3 css={reservationSubtitle}>
            Paskambinkite{" "}
            <a css={reservationSubtitlePhoneButton} href="tel:+37068845247">
              +37068845247
            </a>
          </h3>
        </div>

        {/* <form css={contactForm}>
          <div css={contactFormColumn50}>
            <input
              css={formInputText}
              id="name"
              type="text"
              placeholder="Jūsų vardas *"
              required
            ></input>

            <input
              css={formInputText}
              id="email"
              type="email"
              placeholder="Jūsų elektroninis paštas *"
              required
            ></input>
            <div css={formGroup}>
              <input
                css={formInputText}
                id="phone"
                type="tel"
                placeholder="Mobilus telefonas *"
                required
              ></input>
            </div>
          </div>
          <div css={contactFormColumn50}>
            <textarea
              css={formInuputTextArea}
              id="message"
              placeholder="Problemos/klausimo aprašymas *"
              required="required"
              data-validation-required-message="Aprašykite automobilio
                    problemą arba informaciją, kuri domintų."
            ></textarea>
          </div>

          <button id="sendMessageButton" css={formSubmitButton} type="submit">
            Siųsti
          </button>
        </form> */}
      </div>
    </section>
  )
}

const contactFormColumn50 = css`
  flex: 50%;
`

const contactForm = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`

const formGroup = css``

const formSubmitButton = css`
  background: #feb836;
  text-shadow: none;
  cursor: pointer;
  background-color: #feb836;
  border-color: #feb836;
  font-size: 18px;
  padding: 15px 35px;
  margin: 5px 5px;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

const formInuputTextArea = css`
  height: 100%;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 25px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  &::placeholder {
    opacity: 1;
    font-weight: 700;
    color: #ced4da;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
`

const formInputText = css`
  padding: 0 15px;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 25px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  &::placeholder {
    opacity: 1;
    font-weight: 700;
    color: #ced4da;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
`
const reservationTitle = css`
  font-size: 2.3rem;
  margin-top: 0;
  margin-bottom: 15px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
`

const reservationSubtitle = css`
  font-size: 19px;
  color: #6c757d;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: none;
  text-align: center;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`
const reservationSubtitlePhoneButton = css`
  background-color: #20e24a;
  border-color: #20e24a;
  display: inline-block;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  font-style: normal;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.1rem 0.2rem;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #19c23e !important;
    border-color: #19c23e !important;
    color: #fff;
  }
`
const row = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const reservationContainer = css`
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1200px;
`

const reservationSection = css`
  background-color: #212529;
  background-repeat: no-repeat;
  background-position: center;
  padding: 60px 0 50px 0;
`
